<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <form class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Enviar mensaje</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12 has-text-centered">
            ¿Que mensaje deseas enviar a <strong>{{userName}}?</strong>
          </div>
          <div class="column is-6 containerMsj">
            <div class="columns is-multiline">
              <template v-if="messagesUser.length > 0">
                <div class="field column is-12 messageOld" v-for="message in messagesUser" :key="message.id">
                  <p><strong>Asunto:</strong> {{message.title}}</p>
                  <p><strong>Mensaje:</strong> {{message.rejected_message}}</p>
                  <div class="columns is-multiline footerMessage">
                  <p class="column is-6"><strong>Admin:</strong> {{message.name}}</p>
                  <p class="column is-6 textFooter"><i>{{formatDate(message.created_at)}}</i> </p>
                  </div>
                </div>
              </template>
              <template v-else>
              <div class="field column is-12 messageOld has-text-centered">
                <p>No hay mensajes a este usuario</p>
              </div>
              </template>
            </div>
          </div>
          <div class="column is-6">
            <div class="columns is-multiline">
              <div class="field column is-12" v-if="validateMessage === PENDING_STATUS">
                <label class="label">Plantillas de mensajes</label>
                <div class="select is-fullwidth">
                  <select
                    class="input"
                    type="text"
                    name="messageList"
                    @change="selectMessage"
                  >
                    <option :value="null" disabled selected> Selecciona un mensaje </option>
                    <option :value="0">Mensaje personalizado</option>
                    <template v-for="(message, index) in messagesList">
                      <option :value="message.id" :key="index"> {{message.name}} </option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="field column is-12">
                <label class="label">Asunto</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="asunto"
                    name="asunto"
                    v-validate="'required'"
                    :class="{'is-danger': vverrors.has('asunto')}"
                  >
                  <p class="help is-danger"> {{vverrors.first('asunto')}} </p>
                </div>
              </div>
              <div class="field column is-12">
                <label class="label">Mensaje</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    v-model="mensaje"
                    name="mensaje"
                    v-validate="'required|min:10'"
                    :class="{'is-danger': vverrors.has('mensaje')}"
                  ></textarea>
                  <p class="help is-danger"> {{vverrors.first('mensaje')}} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          type="reset"
          @click="close"
        >
          Cancelar
        </button>
        <button
          class="button button-givu"
          type="button"
          @click="sendMessage"
          :class="{ 'is-loading': loading }"
        >
          Enviar
        </button>
      </footer>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '../../mixin/validateDate'

export default {
  mixins: [mixins],
  name: 'ModalSendMessage',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    },
    userName: {
      type: String,
      default: ''
    },
    messagesUser: {
      type: Array
    },
    validateMessage: {
      type: Number
    }
  },
  data () {
    return {
      asunto: null,
      mensaje: null,
      messagesList: [],
      loading: false,
      PENDING_STATUS: 1
    }
  },
  methods: {
    ...mapActions(['getCatalogPredefinedMessages']),
    async sendMessage () {
      this.loading = true
      if (await this.$validator.validateAll()) {
        const body = {
          asunto: this.asunto,
          mensaje: this.mensaje
        }
        await this.action(body)
        this.loading = false
        this.close()
      }
    },
    selectMessage ({ target }) {
      if (target.value === '0') {
        this.asunto = ''
        this.mensaje = ''
      } else {
        const messageSelected = this.messagesList.find(message => parseInt(message.id) === parseInt(target.value))
        this.asunto = messageSelected.name
        this.mensaje = messageSelected.description
      }
    }
  },
  beforeMount () {
    this.getCatalogPredefinedMessages()
      .then(messages => {
        this.messagesList = messages
      })
  }
}
</script>

<style scoped>
  .containerMsj {
    overflow-y: scroll;
    max-height: 350px;
  }
  .messageOld {
    font-size: 12px;
    border-bottom: 1px solid #F0F0F0
  }
  .messageOld p {
    margin-top: 10px;
  }
  .footerMessage {
    padding-top: -5px;
    font-size: 12px;
  }
  .textFooter {
    font-size: 10px;
    text-align: right;
  }
</style>
